<template>
  <div>
    <div class="text-start px-5 py-2">
      <div class="pb-3 m-3">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <h4>
              {{
                $t("borrow.select_number", selectedCount, {
                  count: selectedCount,
                })
              }}
            </h4>
            <div class="d-flex my-2 overflow-auto align-items-center">
              <button
                class="btn-filled secondary-btn d-flex mx-2 my-1 flex-shrink-0"
                @click="$router.back()"
              >
                <p>{{ $t("borrow.cancel") }}</p>
              </button>
              <button
                class="btn-filled primary-btn d-flex mx-2 my-1 flex-shrink-0"
                @click="confirmSelectAsset"
                :disabled="!selectedCount"
              >
                <p>{{ $t("borrow.confirm_select") }}</p>
              </button>
            </div>
          </div>
          <div class="d-flex flex-wrap justify-content-between">
            <div class="d-flex align-items-center">
              <div class="position-relative m-2" style="height: fit-content">
                <input
                  v-model="filter.text"
                  v-debounce:700ms="loadAsset"
                  type="text"
                  :placeholder="$t('borrow.search')"
                  class="form-control"
                  style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
                />
                <img
                  class="position-absolute top-50 translate-middle-y"
                  style="left: 5px"
                  src="../../assets/search-white.svg"
                  width="20px"
                />
              </div>
              <button
                class="filter flex-shrink-0"
                type="button"
                id="button-addon2"
                @click="filterModal = true"
              >
                <img src="@/assets/filter-white.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <borrow-list-table
        @interface="getBorrowTableInterface"
        :assetData="tableData"
        :otherColumn="otherColumn"
        :page="currentPage"
        :perPage="perPage"
        :searchText="filter.text"
        @selected-count="setSelectedCount"
      ></borrow-list-table>
      <div class="d-flex justify-content-center mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          aria-controls="my-table"
          @input="loadAsset"
        />
      </div>
    </div>
    <base-dialog
      :showModal="removeModal"
      size="XL"
      confirmButtonColor="#ED1616"
      @confirm-action="removeBorrowableAssets"
      @cancel-action="removeModal = false"
    >
      <h4 class="text-center" style="color: #ED1616">
        {{ $t("borrow.remove_borrow_asset_dialog") }}
      </h4>
      <p class="text-center">{{ $t("borrow.confirm_remove_asset_dialog") }}</p>
      <p class="text-center" style="color: #ED1616">
        {{ $t("borrow.warn_remove_dialog") }}
      </p>
      <div class="mx-3">
        <remove-summary-table
          :assetData="selectedRemoveAsset"
          :otherColumn="otherColumn"
          :page="currentPage"
          :perPage="perPage"
          :rowSelection="'none'"
          @selected-count="setSelectedCount"
        ></remove-summary-table>
      </div>
    </base-dialog>
    <asset-filter
      :showModal="filterModal"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { authHeader } from "../../store/actions";
import { borrowUrl } from "@/util/backend";
import moment from "moment";

import BaseDialog from "../../components/BaseDialog.vue";
import BorrowListTable from "../../components/Table/BorrowReturn/BorrowListTable.vue";
import RemoveSummaryTable from "../../components/Table/BorrowReturn/RemoveSummaryTable.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
export default {
  components: {
    BaseDialog,
    BorrowListTable,
    RemoveSummaryTable,
    AssetFilter,
  },
  data() {
    return {
      BorrowListTableFunction: {
        getSelectedRowData: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      id_company: null,
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      currentPage: 1,
      perPage: 10,
      filterModal: false,
      filter: {
        text: null,
      },
      filterOptions: null,

      tableData: [],
      otherColumn: [],
      totalRows: 0,
      selectedCount: 0,

      removeModal: false,
      selectedRemoveAsset: [],
    };
  },
  methods: {
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    async removeBorrowableAssets() {
      const asset_ids = this.selectedRemoveAsset?.map(
        (asset) => asset.asset_id
      );

      try {
        const config = authHeader();
        await axios.delete(
          `${borrowUrl()}asset/company/id/${this.id_company}`,
          {
            ...config,
            data: {
              asset_ids,
            },
          }
        );
        Swal.fire({
          icon: "success",
          title: this.$t("borrow.remove_assets_success"),
          showConfirmButton: false,
          timer: 2000,
          onDestroy: () => {
            this.removeModal = false;
            this.selectedRemoveAsset = [];

            setTimeout(() => {
              this.$router.push({
                name: "borrow-list",
              });
            }, 500);
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
    getBorrowTableInterface(childInterface) {
      this.BorrowListTableFunction = childInterface;
    },
    setSelectedCount(value) {
      this.selectedCount = value;
    },
    handleStatusFilter(status) {
      this.filter.status = status;
    },
    confirmSelectAsset() {
      this.selectedRemoveAsset = Array.from(
        this.BorrowListTableFunction.getSelectedRowData(),
        ([name, value]) => value
      );
      this.removeModal = true;
    },
    async loadAsset() {
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;
      this.BorrowListTableFunction.showLoading();
      const response = await axios.get(
        `${borrowUrl()}asset/list/company/id/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            page: this.currentPage,
            limit: this.perPage,
            start_datetime: moment()
              .startOf("h")
              .toISOString(),
            end_datetime: moment()
              .add(1, "hours")
              .startOf("h")
              .toISOString(),
          },
          ...authHeader(),
        }
      );
      this.tableData = response.data.results;
      this.totalRows = response.data.item_count;
      this.BorrowListTableFunction.hideLoading();
    },
  },
  created() {
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
.btn-filled {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &.primary-btn {
    color: #fff;
    background-color: #007afe;
    border-color: #007afe;
  }
  &.secondary-btn {
    color: #fff;
    background-color: #818181;
    border-color: #818181;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default !important;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}
</style>
